import i18n from 'i18n';
i18n.init({ defaultNS: 'success_error_messages' });

export const ERROR_MAP: any = {
  invalid_grant: i18n.t('msg_err_invalid_credentials'),
  unauthorized: i18n.t('msg_err_invalid_credentials'),
  account_does_not_match_portal: i18n.t('msg_err_invalid_credentials'),
  the_access_token_expired: 'Token expired!',
  account_is_not_yet_active: 'Account is not yet active'
};
